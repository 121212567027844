import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Layout } from "../layouts/Site"
import { Content } from "../components/Content"
import { AssertPage } from "../components/AssetContext"
import { PageWidth, Section } from "../components/Containers"
import { Image } from "../components/Image"
import { Link, PageHeading, pageHeadingStyles, SectionHeading } from "../components/Headings";
import { Testimonials } from "../components/Testimonial";
import { Curve } from "../components/PageWidthElements";
import { RichText } from "../components/RichText";
import { faFacebookF, faLinkedinIn, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { join } from "../styles/styles";
import { Helmet } from "react-helmet";

const linkedInProfile = "https://linkedin.com/in/"
const twitterProfile = "https://twitter.com/"
const facebookProfile = "https://facebook.com/"
const instagramProfile = "https://instagram.com/"

export default function AboutUsPage({ location }) {
  const { page, defaultMetadata } = useStaticQuery(query)

  return <Layout title={page.name}>
    <Helmet>
      <meta name="description" content={page.metaDescription || defaultMetadata.metaDescription} />
    </Helmet>
    <AssertPage id={page.contentful_id} url={location.pathname} />
    {page.contents.map(createTopLevelComponent)}
  </Layout>
}

function createTopLevelComponent(content, index) {
  if (index === 0)
    return <HeaderSection content={content} />

  if (content.key === 'about-us-testimonials')
    return <TestimonialSection content={content} />

  if (content.key === 'team')
    return <TeamSection content={content} />

  if (content.__typename !== "ContentfulSiteContent")
    return null

  if (index === 1)
    return <SubHeaderSection content={content} />

  return <Content content={content} />
}

function HeaderSection({content}) {
  return <>
    <Section className="pt-header md:pt-header-md">
      <Image image={content.image} />
    </Section>
    <Curve className='bg-page text-primary' top/>
    <PageWidth className='pt-10 bg-primary text-page'>
      <Section>
        <Content content={content} richTextStyles={pageHeadingStyles} className='self-center' hideBackgroundImage />
      </Section>
    </PageWidth>
  </>
}

function SubHeaderSection({content}) {
  return <PageWidth className='pt-10 bg-primary text-page'>
      <Section>
        <Content content={content} richTextStyles={pageHeadingStyles} className='self-center' hideBackgroundImage />
      </Section>
    </PageWidth>
}

function TestimonialSection({content}) {
  return <>
    <PageWidth className='bg-primary text-page'>
      <Section className='md:px-48 text-center'>
        <Testimonials testimonials={content} bgColor='bg-page' color='text-ink' hideTitle />
      </Section>
    </PageWidth>
    <Curve className='bg-page text-primary' />
  </>
}

function TeamSection({content}) {
  return <PageWidth className='mt-8'>
    <div className='xl:max-w-4/5 xl:mx-auto'>
      <PageHeading>{content.name}</PageHeading>
      <div className='grid grid-cols-none lg:grid-cols-2 gap-6'>
        {content.contents.map((teamMember, idx) => <ProfileCard content={teamMember} rightAligned={idx % 2}/>)}
      </div>
    </div>
  </PageWidth>
}

function ProfileCard({content, rightAligned}) {
  return <div className='w-full rounded-xl shadow-md mb-0 relative overflow-hidden min-h-72'>
    <Image image={content.image} className={join(rightAligned ? 'right-0' : 'left-0', 'absolute w-64 bottom-0')} />
    <SectionHeading className={join(rightAligned ? 'text-right' : 'text-left', 'p-4 pb-0 z-10')}>{content.name}</SectionHeading>
    <RichText body={content.description} className={join(rightAligned ? 'md:mr-56' : 'md:ml-56', 'p-4 pt-0 pb-8 mb-48 md:mb-0')}/>
    <div className={join(rightAligned ? 'left-0' : 'right-0', 'm-1 p-1 space-x-1 absolute bottom-0')}>
      {content.linkedin && <ProfileLink icon={faLinkedinIn} url={linkedInProfile + content.linkedin} className='bg-linkedin' />}
      {content.twitter && <ProfileLink icon={faTwitter} url={twitterProfile + content.twitter} className='bg-twitter' />}
      {content.facebook && <ProfileLink icon={faFacebookF} url={facebookProfile + content.facebook} className='bg-facebook' />}
      {content.instagram && <ProfileLink icon={faInstagram} url={instagramProfile + content.instagram} className='bg-instagram' />}
    </div>
  </div>
}

function ProfileLink({icon, url, className}) {
  return <Link href={url} className={join(className, 'inline-block rounded-lg w-8 h-8 text-center leading-relaxed text-xl')}>
    <Icon icon={icon} className="text-page" />
  </Link>
}

const query = graphql`{
  page: contentfulSiteContentList(key: {eq: "about-us"}) {
    key
    name
    metaDescription
    contentful_id
    contents {
      ... on ContentfulSiteContent {
        ...Content
      }
      ... on ContentfulSiteContentList {
        __typename
        key
        name
        contents {
          ... on ContentfulSiteContent {
            ...Content
          }
          ... on ContentfulSiteTestimonial {
            ...Testimonial
          }
          ... on ContentfulSiteTeamMember {
            name
            description {
              raw
            }
            image {
              ...ImageContent
            }            
            linkedin
            twitter
            facebook
            instagram
          }
        }
      }
    }
  }
  defaultMetadata: contentfulSiteContentList(key: {eq: "index"}) {
    metaDescription
  }
}`